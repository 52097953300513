<template>
  <div class="ticket-box">
    <div class="d-flex justify-content-between mb-0 px-2">
      <span>แสดง 15 รายการล่าสุด</span>
      <router-link :to="{name: 'Tickets'}" class="font-weight-normal">เพิ่มเติม</router-link>
    </div>
    <div class="round-table position-relative">
      <table class="table table-sm table-bordered table-hover mb-0">
        <thead>
          <tr>
            <th width="7%">#</th>
            <th width="10%">เลขที่</th>
            <th width="15%">เวลาแทง</th>
            <th width="15%">หวย</th>
            <th width="10%">รายการ</th>
            <th width="15%">บาท</th>
            <th width="20%">หมายเหตุ</th>
            <th width="8%">ลบโพย</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items"
            :class="[
              {'alert-danger': isCanceled(item.status)},
              {'alert-warning': item.status==='Refund'}
            ]">
            <td class="text-center" @click="viewTicket(item._id)">
              <i v-if="isCanceled(item.status)" class="fas fa-times text-danger bigger-175"></i>
              <i v-if="item.status==='Refund'" class="fas fa-undo text-warning fa-xs"></i>
              {{index+1}}
            </td>
            <td class="text-center">{{item.ticketNo}}</td>
            <td class="text-center" @click="viewTicket(item._id)">{{ item.updatedAt | dispDateTime("DD/MM/YYYY") }}<br />{{ item.updatedAt | dispDateTime("HH:mm:ss") }}</td>
            <td class="text-center" @click="viewTicket(item._id)">[{{ item.market.marketTitle }}]<br />{{ item.round.roundDate | dispDateTime("DD/MM/YYYY") }}</td>
            <td class="text-center" @click="viewTicket(item._id)">{{ summary(item, 'length') }}</td>
            <td class="text-right" @click="viewTicket(item._id)">{{ summary(item, 'amount') | amountFormat(2, '0.00') }}</td>
            <td class="text-center" @click="viewTicket(item._id)">{{ item.remarks || '-' }}</td>
            <td class="text-center">
              <button type="button" class="btn text-danger btn-link btn-sm" :disabled="isDisabled(item.status)" @click="cancelTicket(item._id)"><i class="far fa-trash-alt fa-sm"></i></button>
            </td>
          </tr>
          <tr v-if="!items.length">
            <td colspan="8" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
      </table>

      <ViewTicketModal :is-show="isShowTicketModal" :ticket-id="viewTicketId" @close="isShowTicketModal=false" />

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="30"
        :width="30"
        color="#007bff"
      />
    </div>
  </div>
</template>
<script>
import ReportService from '@/services/ReportService'
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import cryptoRandomString from 'crypto-random-string'
import ViewTicketModal from '@/views/report/components/ViewTicketModal'
import cAlert from '@/helpers/alert'

export default {
  name: 'RecentTickets',
  props: ['reload'],
  components: {
    ViewTicketModal
  },
  data() {
    return {
      isLoading: false,
      data: null,
      isShowTicketModal: false,
      viewTicketId: null
    }
  },
  computed: {
    items() {
      return (this.data?.items || []).filter((item)=>{
        return item?.summary?.length
      })
    }
  },
  watch: {
    reload(n, o) {
      if(n) {
       this.loadTickets()
      }
    }
  },
  methods: {
    loadTickets() {
      this.isLoading = true
      ReportService.getTickets().then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'ดึงรายการโพยไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
        this.$emit('success')
      })
    },
    cancelTicket(ticketId) {
      const uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
      Swal.fire({
        text: 'ยืนยันยกเลิกโพย!',
        icon: 'info',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        if(res.isConfirmed) {
          this.isLoading = true
          ReportService.cancelTicket(ticketId, {uniqueCode}).then((response)=>{
            if(response.success) {
              this.$store.dispatch('reloadBalance')
              this.$notify({
                type: 'success',
                title: 'ยกเลิกโพยแล้ว!'
              })
            }else{
              throw new Error(response?.message)
            }
          })
          .catch((e)=>{
            cAlert({
              ...e,
              title: 'ผิดพลาด!',
              text: 'ยกเลิกโพยไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
          .finally(()=>{
            this.isLoading = false
            this.loadTickets()
          })
        }
      })
    },
    isCanceled(status) {
      return status === 'Canceled'
    },
    isDisabled(status) {
      return ['Canceled', 'Refund'].includes(status)
    },
    viewTicket(ticketId) {
      this.isShowTicketModal = true
      this.viewTicketId = ticketId
    },
    summary(item, filed) {
      return item?.summary?.[filed] || 0
    }
  },
  mounted() {
    this.loadTickets()
  }
}
</script>
<style lang="scss" scoped>
.ticket-box {
  .round-table {
    background-color: #FFF;
    min-height: 50px;
  }
  table {
    thead {
      tr {
        th {
          font-size: 85%;
          text-align: center;
          // font-weight: normal;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 75%;
          vertical-align: middle;
          cursor: pointer;
          // text-align: center;
        }
      }
    }
  }
}
</style>
