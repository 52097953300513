<template>
  <div class="round-table">
    <table class="table table-sm table-striped table-bordered">
      <thead>
        <tr class="bg-secondary">
          <th>เลข</th>
          <th width="40%">จ่ายครึ่ง</th>
          <th width="40%">ปิดรับ</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="bet in marketOpenBets" :key="bet.code">
          <td class="text-center">{{bet.text}}</td>
          <td>{{ roundSettingHalfPrice[bet.code] | dispData }}</td>
          <td>{{ roundSettingCloseNumbers[bet.code] | dispData }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { marketOpenBets } from '@/configs/market.config'
import _ from 'lodash'

const defaultData = {
  threeNumberTop: [],
  threeNumberTode: [],
  twoNumberTop: [],
  twoNumberBottom: [],
  runTop: [],
  runBottom: []
}

export default {
  name: 'RoundCloseNumbers',
  props: ['round'],
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    roundSettingCloseNumbers() {
      if(!this.round)
        return defaultData

      return this.round?.closeNumbers || {}
    },
    roundSettingHalfPrice() {
      if(!this.round)
        return defaultData

      return this.round?.payHalfNumbers || {}
    },
  },
  filters: {
    dispData(val) {
      return _.sortBy(val, (n)=>{
        return parseInt(n)
      }).join(' - ') || '-'
    }
  }
}
</script>
<style lang="scss" scoped>
.round-table {
  // background-color: #FFF;
}
table {
  thead {
    tr {
      th {
        font-size: 85%;
        text-align: center;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 85%;
      }
    }
  }
}
</style>
